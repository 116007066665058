#cards{
    margin-top: 70px;
}

@media(max-width:768px) {
    #cards, .cards, .cardheader,.learnmore {
        text-align:center;
    }
    .cards-img {
        margin-top:15%;
    } 
}

.cards-img {
    position: relative; 
}
.img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 0;
    transition: all 0.3s ease-in-out;
}

.img-overlay:hover {
    opacity: 1;
    z-index: 1;
}

.card {
    margin-bottom:40px;
    border: 0px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.learnmore{
    color : #0078d2;
    font-size: 18px;
    padding-left : 15px;
    padding-bottom: 15px;
}

.learnmore:hover {
    text-decoration: underline;
}

.card-body{
    padding-top: 5px;
}

.cardheader {
    font-size: 24px;
    color: #627a88;
    font-size: 24px;
    letter-spacing: -0.1px;
    padding-top:10px;
    padding-left : 15px;
}

