@media(max-width:768px) {
    #mainview {
        text-align:center;
    }
    .paragraph{
        margin: 0 auto;
        text-align: center;
    }
    .bodycontent{
        margin: 0 auto 0;
        text-align: center;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        display: block;
    }

    .body, .featuresandbenefits, .fltAttendantImg,  .requestacharterlinkcardscroll,  .charterexperience{
        text-align: center;
    }
    .cards {
        text-align:center;
    }

    #mainview .maincontent {
        bottom:11vmin;
    }
}


#mainview {
    align-items: center;
}

.backgroundImg{
    position: relative;
}


.body {
    background-color: #fff;
    border-color: #ccc;
    margin-left: 45px;
    margin-right: 45px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.maincontent {
    z-index: 999;
    position: relative;
    bottom:20vmin;
}
.bodycontent {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 20px;
    font-size: 18px;
    letter-spacing: -0.07px;
    color: #414d5d;
}

.featuresandbenefits {
    padding-top: 20px;
    padding-bottom: 16px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 18px;
    color: #414d5d;
}

.paragraph {
    margin-left: 45px;
    margin-right: 40px;
    padding-top: 13px;
    letter-spacing: -0.07px;
    color: #414d5d;
}

.fltAttendantImg {
    padding-top: 13px;
    margin-left: 60px;
}

.requestacharterlinkcardscroll{
    padding-top: 20px;
    margin-left:5px;
    color : #0078d2;
    font-size:18px;
    padding-bottom: 16px;
}

.cards{
    padding-left: 45px;
    padding-right:45px;
}

.charterexperience {
    font-size: 30px;
    letter-spacing: -0.12px;
    color: #00467f;
    margin-bottom: 30px;
}

.scrolltocards{
    padding-left: 0;
}
