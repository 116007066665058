.section-header {
    font-size: 33px;
    letter-spacing: -0.16px;
    color: #0277d1;
    margin-top: 15px;
    margin-left: 30px;
}

.Frequently-Asked-Que {
    font-size: 30px;
    line-height: 26px;
    letter-spacing: -0.12px;
    margin: 0 0 11px 0;
    color: #00467f;
}

.text-style-1 {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01px;
    color: #627a88;
}

.text-style-2 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.06px;
    color: #627a88;
}

.FAQ {
    margin: 10px 30px 40px 30px;
    padding: 30px 30px 30px 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.Are-there-any-limits {
    margin: 15px 0 0 0;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.06px;
    color: #596471;
    padding-left: 15px;
    padding-right: 15px;
}

.text-style-3 {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01px;
    color: #627a88;
}

.text-style-4 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.06px;
    color: #627a88;
}

.planningyourtripimage {
    margin-top: 40px;
    padding-right: 45px;
}
