#chartersHeader {
    text-align: center;
}

@media(max-width:768px) {
    .navbar .nav {
        display: block;
        text-align: left;
    }
    .nav-item {
        margin-top: auto;
        padding-top: auto;
    }
    /* #charterslogo .logoImg{
        background-image: url(../../../img/charterslogosmall.jpg);
    } */
    #navigation .button-navlink {
        padding: 0;
    }
    #navigation .nav-item {
        padding-top: 0.5rem
    }
    #navigation{
        width: 100%;
    }
}

.logoImg {
    /* background-image: url(../../../img/AAChartersLogo.png); */
    margin-right: 110px;
    margin-top: 10px;
    padding-right: 15px;
}

.hamburgermenu {
    margin-top: 25px;
    color: #0275d8;
    background: transparent;
    border: none;
    padding-left: 0;
}

.hamburgermenu:focus {
    outline: 1px solid #ffffff;
}

.navbar {
    background-color: #ffffff;
    padding-top: 4px;
    padding: 0 0;
}

.navbar .nav .nav-item {
    padding-top: 2rem
}

.navbar .nav .nav-item .nav-link:hover {
    text-decoration: underline;
}

/* 
.navbar-toggler {
    color: #36495a solid;
    outline: 2px solid #ffffff;
    padding-left:0;
}

.navbar-toggler:focus {
    outline: 2px solid #ffffff;
} */

.navbar a img {
    border: none;
}

.button-navlink {
    color: #0078d2;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    height: 2.57rem;
    padding: 0 15px 0 15px;
}

.button-navlink:hover {
    text-decoration: underline;
}

.button-navlink:focus {
    outline: 2px solid #0078d2;
}

.btn {
    transition: all 0.3s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #0078d2;
    border: 1px solid #0078d2;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    height: 2.57rem;
    padding: 0 15px 0 15px;
}

.btn-primary:hover {
    text-decoration: underline;
    background-color: #0078d2;
    border-color: #0078d2;
}

.btn-secondary {
    color: #0078d2;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #0078d2;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    height: 2.57rem;
    padding: 0 15px 0 15px;
}

.btn-secondary:hover {
    text-decoration: underline;
    color: #0078d2;
    background-color: rgba(0, 0, 0, 0);
    border-color: #0078d2;
}

.btn-secondary:active {
    background-color: none;
    border-color: #0078d2;
}

.charterexperienceexpanded {
    padding-top: 25px;
}

.chartersHeader {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
}

.shadow {
    color: #36495a;
    line-height: 18px;
    font-weight: 400;
    padding: 0 0 8px;
    display: block;
    background-image: url(../../../img/shadow12-down.png);
    background-position: 50% top;
    background-repeat: repeat-x;
    background-position: 50% bottom;
}

.charterexperienceexpanded .nav-item {
    color: #637987;
}

.charterexperienceexpanded .nav-item .submenuatags {
    color: #637987;
}

.charterexperienceexpanded .nav-item .submenuatags:focus {
    color: #0078d2
}

.charterexperienceexpanded .caretuparrow {
    color: #637987;
}

/** Give the nav a width */

.nav1 {
    width: 100%;
}

/** Set the flex properties */

.nav_links {
    display: flex;
    justify-content: space-between;
}