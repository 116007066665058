@media(max-width:768px) {
    #footer {
        text-align: center;
    }
    #footer h2 {
        text-align: center;
    }
    .footerbottom {
        text-align: center;
    }
}

#footer {
    padding: 1rem 0;
    background-color: #d0dae0;
    color: #36495a;
    line-height: 18px;
}

#footer a {
    color: #36495a;
}

#footer h2 {
    color: #36495a;
    font-size: 20px;
    line-height: 18px;
    font-weight: 400;
    padding: 0 0 13px;
    margin: 0 0 14px;
    display: block;
    background-image: url(../../../img/shadow12-down.png);
    background-position: 50% top;
    background-repeat: no-repeat;
    background-position: 50% bottom;
}

#footer .footerbottom {
    color: #36495a;
    line-height: 18px;
    font-weight: 400;
    padding: 0 0 13px;
    margin: 0 0 14px;
    display: block;
    background-image: url(../../../img/shadow12-down.png);
    background-position: 50% top;
    background-repeat: no-repeat;
    background-position: 50% bottom;
    width: 100%;
}

#footer .footerText {
    font-size: 14px;
    margin-left: 60px;
}

#footer .footer {
    margin-left: 45px;
    margin-right: 45px;
}

.ulstyle {
    list-style-type: none;
    padding-left: 0;
    font-size: 14px;
    display: block;
    line-height: 30px;
}


.columnFooter{
    padding-left:0;
}